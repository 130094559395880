import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { UncontrolledTooltip } from 'reactstrap';

import './tooltip.styles.scss';

// TODO this variable name is misleading, it should be changed to targetId
const Tooltip = ({ id, children, placement, innerClassName, isAutohide }) => (
  <UncontrolledTooltip
    target={id}
    className={`cc-tooltip cc-tooltip-${placement}`}
    arrowClassName="cc-tooltip-arrow"
    autohide={!isAutohide}
    innerClassName={classNames('cc-tooltip-inner rounded bg-white text-gray-darkest text-left', innerClassName)}
    placement={placement}
    boundariesElement="body"
  >
    {children}
  </UncontrolledTooltip>
);

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  placement: PropTypes.string,
  innerClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isAutohide: PropTypes.bool,
};

Tooltip.defaultProps = {
  children: null,
  placement: 'left-start',
  innerClassName: null,
  isAutohide: false,
};

export default Tooltip;
