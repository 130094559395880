import { notificationService } from 'core/notification';

const getBrowserNotificationObject = (message, hideCustomerPhone) => {
  const notification = {
    id: message.id,
  };

  const notificationTitle = [];
  if (message.customer.name) {
    notificationTitle.push(message.customer.name);
  }
  if (message.customer.phoneNumber && !hideCustomerPhone) {
    notificationTitle.push(message.customer.phoneNumber);
  }
  notification.title = notificationTitle.join(', ');

  notification.body = message.msgStats.lastMsgText;
  if (notification.body === '' && message.hasAttach) {
    notification.body = 'Message with attachment';
  }

  return notification;
};

const showNotification = ({ notification: { title, body, id }, onClick }) => {
  const notification = notificationService.createNotification({
    title,
    options: {
      body,
      delay: 0,
      icon: '/images/notification/notification.png',
    },
  });

  if (onClick) {
    notification.onclick = e => {
      onClick(e);
      notification.close();
    };
  }
};

const notify = ({ notification, onNotificationClick }) => {
  if (notificationService.isPermissionGranted()) {
    showNotification({ notification, onClick: onNotificationClick });
  } else if (!notificationService.isPermissionDenied()) {
    notificationService.requestPermission({
      onGranted: () => {
        showNotification({ notification, onClick: onNotificationClick });
      },
    });
  }
};

export default {
  getBrowserNotificationObject,
  notify,
};
