import { API } from 'core/api';
import { LONG_LIST_SIZE, INFINITE_LIST_SIZE, MEDIUM_LIST_SIZE, SHORT_LIST_SIZE, } from 'core/constants/inquiries/pagination';
import { SHORT_LIST_LIMIT_SIZE } from 'core/constants/inquiries/limit';
import { SHORT_LIST_THERSHOLD_SIZE } from 'core/constants/inquiries/threshold';
import { SEARCH_TYPE } from 'core/constants/search-type';
import { USER_PERMISSION } from 'core/constants/user';
export const INITIAL_SUB_CATEGORIES_ID = 100;
export const INQUIRIES_CATEGORY_STORAGE_KEY = 'inquiries-category';
export const INQUIRIES_ORDER_STORAGE_KEY = {
    ALL_ADMIN_LIST: 'all-admin-list-order',
    ALL_AGENT_ASSIGNED_LIST: 'all-agent-assigned-list-order',
    ALL_AGENT_UNASSIGNED_LIST: 'all-agent-unassigned-list-order',
    ASSIGNED_TO_ME_LIST: 'assigned-to-me-list-order',
    UNASSIGNED_LIST: 'unassigned-list-order',
    UNANSWERED_LIST: 'unanswered-list-order',
    COMPLETED_LIST: 'completed-list-order',
    MANAGED_LIST: 'managed-list-order',
    OUTBOUND_LIST: 'outbound-list-order',
    ASSIGNED_LIST: 'assigned-list-order',
    ARCHIVED_LIST: 'archived-list-order',
    ALL_LIST: 'all-list-order',
};
export const CATEGORY_LIST_KEY = {
    ALL: 'category-list-all',
    ASSIGNED: 'category-list-all-assigned-to-others',
    ASSIGNED_TO_ME: 'category-list-assigned-to-me',
    ALL_ASSIGNED_TO_ME: 'category-list-all-assigned-to-me',
    UNASSIGNED: 'category-list-all-unassigned',
    COMPLETED: 'category-list-completed',
    MANAGED: 'category-list-managed',
    OUTBOUND: 'category-list-outbound',
    ARCHIVED: 'category-list-archived',
    UNANSWERED: 'category-list-unanswered',
};
export const CATEGORY = {
    ALL: {
        id: 1,
        name: 'All',
        key: 'all',
        keyMapper: 'total',
        description: '',
        includeStructureKey: 'includeAll',
        permissions: {
            some: [USER_PERMISSION.ADMIN, USER_PERMISSION.USER, USER_PERMISSION.CALL_CENTER_ADMIN],
            not: null,
        },
    },
    UNANSWERED: {
        id: 2,
        name: 'Unanswered',
        key: 'unanswered',
        keyMapper: 'total',
        description: '',
        includeStructureKey: 'includeUnanswered',
        permissions: {
            some: [USER_PERMISSION.ADMIN, USER_PERMISSION.USER],
            not: [USER_PERMISSION.AGENT],
        },
    },
    UNASSIGNED: {
        id: 3,
        name: 'Unassigned',
        key: 'unassigned',
        keyMapper: 'total',
        description: '',
        includeStructureKey: 'includeUnassigned',
        permissions: {
            some: [USER_PERMISSION.ADMIN, USER_PERMISSION.USER],
            not: [USER_PERMISSION.AGENT],
        },
    },
    ASSIGNED_TO_ME: {
        id: 4,
        name: 'Assigned to me',
        key: 'assignedToMe',
        keyMapper: 'total',
        description: '',
        includeStructureKey: 'includeAssignedToMe',
        permissions: {
            some: [USER_PERMISSION.ADMIN, USER_PERMISSION.USER],
            not: [USER_PERMISSION.AGENT],
        },
    },
    ASSIGNED_TO_OTHERS: {
        id: 5,
        name: 'Assigned',
        key: 'assignedToOthers',
        keyMapper: 'total',
        description: 'Inquiries assigned to users other than yourself.',
        includeStructureKey: 'includeAssigned',
        permissions: {
            some: [USER_PERMISSION.ADMIN],
            not: null,
        },
    },
    MANAGED: {
        id: 6,
        name: 'Managed',
        key: 'managed',
        keyMapper: 'total',
        description: '',
        includeStructureKey: 'includeManaged',
        permissions: {
            some: [USER_PERMISSION.ADMIN, USER_PERMISSION.USER],
            not: [USER_PERMISSION.CALL_CENTER_ADMIN, USER_PERMISSION.AGENT],
        },
    },
    OUTBOUND: {
        id: 7,
        name: 'Outbound',
        key: 'outbound',
        keyMapper: 'total',
        description: '',
        includeStructureKey: 'includeOutbound',
        permissions: {
            some: [USER_PERMISSION.ADMIN, USER_PERMISSION.USER],
            not: [USER_PERMISSION.CALL_CENTER_ADMIN, USER_PERMISSION.AGENT],
        },
    },
    COMPLETED: {
        id: 8,
        name: 'Completed',
        key: 'completed',
        keyMapper: 'total',
        skipStats: true,
        description: '',
        includeStructureKey: null,
        permissions: {
            some: [USER_PERMISSION.ADMIN, USER_PERMISSION.USER],
            not: null,
        },
    },
    ARCHIVED: {
        id: 9,
        name: 'Archived',
        key: 'archived',
        keyMapper: 'total',
        description: '',
        includeStructureKey: null,
        permissions: {
            some: [USER_PERMISSION.ADMIN, USER_PERMISSION.USER],
            not: [USER_PERMISSION.CALL_CENTER_ADMIN, USER_PERMISSION.AGENT],
        },
    },
};
export const CATEGORIES = Object.values(CATEGORY);
export const CATEGORIES_OPTIONS_SHARED_DATA = {
    ASSIGNED_TO_OTHERS: {
        title: 'Assigned',
        dataTestAttributes: {
            list: 'cc-inquiries-list-assigned-to-others',
            item: 'cc-inquiries-list-assigned-to-others-item',
        },
        toolbar: {
            order: {
                storageKey: INQUIRIES_ORDER_STORAGE_KEY.ASSIGNED_LIST,
            },
        },
        query: {
            options: {
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                variables: {
                    searchType: SEARCH_TYPE.DEFAULT,
                    isCompleted: false, // client boolean param for @skip gql directive
                    isHandedOff: false,
                    filters: {
                        assignedToOthers: true,
                        managed: false,
                    },
                    pagination: {
                        pageSize: MEDIUM_LIST_SIZE,
                    },
                },
                notifyOnNetworkStatusChange: true,
            },
        },
    },
    ASSIGNED_TO_ME: {
        title: 'Assigned to me',
        dataTestAttributes: {
            list: 'cc-inquiries-list-assigned-to-me',
            item: 'cc-inquiries-list-assigned-to-me-item',
        },
        toolbar: {
            order: {
                storageKey: INQUIRIES_ORDER_STORAGE_KEY.ASSIGNED_TO_ME_LIST,
            },
        },
        applyLoggedParams: true,
        query: {
            options: {
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                variables: {
                    searchType: SEARCH_TYPE.DEFAULT,
                    isCompleted: false, // client boolean param for @skip gql directive
                    isHandedOff: false,
                    pagination: {
                        pageSize: MEDIUM_LIST_SIZE,
                    },
                },
                notifyOnNetworkStatusChange: true,
            },
        },
    },
    MANAGED: {
        title: 'Managed',
        dataTestAttributes: {
            list: 'cc-inquiries-list-managed',
            item: 'cc-inquiries-list-managed-item',
        },
        toolbar: {
            order: {
                storageKey: INQUIRIES_ORDER_STORAGE_KEY.MANAGED_LIST,
            },
        },
        query: {
            options: {
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                variables: {
                    searchType: SEARCH_TYPE.DEFAULT,
                    isCompleted: false, // client boolean param for @skip gql directive
                    isHandedOff: false,
                    pagination: {
                        pageSize: MEDIUM_LIST_SIZE,
                    },
                    filters: {
                        managed: true,
                    },
                },
                notifyOnNetworkStatusChange: true,
            },
        },
    },
    HANDED_OFF: {
        title: 'Completed',
        dataTestAttributes: {
            list: 'cc-inquiries-list-completed',
            item: 'cc-inquiries-list-completed-item',
        },
        toolbar: {
            order: {
                storageKey: INQUIRIES_ORDER_STORAGE_KEY.COMPLETED_LIST,
            },
        },
        query: {
            options: {
                fetchPolicy: 'cache-and-network',
                variables: {
                    searchType: SEARCH_TYPE.COMPLETED,
                    isCompleted: false, // client boolean param for @skip gql directive
                    isHandedOff: true,
                    pagination: {
                        pageSize: LONG_LIST_SIZE,
                    },
                },
            },
        },
    },
    UNASSIGNED: {
        title: 'Unassigned',
        dataTestAttributes: {
            list: 'cc-inquiries-list-unassigned',
            item: 'cc-inquiries-list-unassigned-item',
        },
        toolbar: {
            order: {
                storageKey: INQUIRIES_ORDER_STORAGE_KEY.UNASSIGNED_LIST,
            },
        },
        clientFilterParams: {
            assigned: {
                id: null,
            },
        },
        query: {
            options: {
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                variables: {
                    searchType: SEARCH_TYPE.DEFAULT,
                    isCompleted: false, // client boolean param for @skip gql directive
                    isHandedOff: false,
                    filters: {
                        assignedTo: -1,
                    },
                    pagination: {
                        pageSize: MEDIUM_LIST_SIZE,
                    },
                },
                notifyOnNetworkStatusChange: true,
            },
        },
    },
    UNANSWERED: {
        title: 'Unanswered',
        dataTestAttributes: {
            list: 'cc-inquiries-list-unanswered',
            item: 'cc-inquiries-list-unanswered-item',
        },
        toolbar: {
            order: {
                storageKey: INQUIRIES_ORDER_STORAGE_KEY.UNANSWERED_LIST,
            },
        },
        query: {
            options: {
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                variables: {
                    searchType: SEARCH_TYPE.UNANSWERED,
                    isCompleted: false, // client boolean param for @skip gql directive
                    isHandedOff: false,
                    pagination: {
                        pageSize: MEDIUM_LIST_SIZE,
                    },
                },
                notifyOnNetworkStatusChange: true,
            },
        },
    },
    OUTBOUND: {
        title: 'Outbound',
        dataTestAttributes: {
            list: 'cc-inquiries-list-outbound',
            item: 'cc-inquiries-list-outbound-item',
        },
        toolbar: {
            order: {
                storageKey: INQUIRIES_ORDER_STORAGE_KEY.OUTBOUND_LIST,
            },
        },
        query: {
            options: {
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                variables: {
                    searchType: SEARCH_TYPE.OUTBOUND,
                    isCompleted: false, // client boolean param for @skip gql directive
                    isHandedOff: false,
                    pagination: {
                        pageSize: MEDIUM_LIST_SIZE,
                    },
                },
                notifyOnNetworkStatusChange: true,
            },
        },
    },
    ALL: {
        title: 'All',
        dataTestAttributes: {
            list: 'cc-inquiries-list-all',
            item: 'cc-inquiries-list-all',
        },
        toolbar: {
            order: {
                storageKey: INQUIRIES_ORDER_STORAGE_KEY.ALL_LIST,
            },
        },
        query: {
            options: {
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
                variables: {
                    searchType: SEARCH_TYPE.DEFAULT,
                    isCompleted: false, // client boolean param for @skip gql directive
                    isHandedOff: false,
                    filters: null, // set filters to null in order to return list of all inquiries
                    pagination: {
                        pageSize: LONG_LIST_SIZE,
                    },
                },
                notifyOnNetworkStatusChange: true,
            },
        },
    },
};
export const CATEGORY_OPTIONS_MAPPER = {
    [CATEGORY.ALL.key]: {
        adminList: [
            Object.assign({ key: CATEGORY_LIST_KEY.ALL, wsUrlApiKey: API.WS_URL_KEY_MAPPER.USER }, CATEGORIES_OPTIONS_SHARED_DATA.ALL),
        ],
        callCenterAdminList: [
            Object.assign({ key: CATEGORY_LIST_KEY.ASSIGNED, wsUrlApiKey: API.WS_URL_KEY_MAPPER.USER }, CATEGORIES_OPTIONS_SHARED_DATA.ASSIGNED_TO_OTHERS),
            Object.assign({ key: CATEGORY_LIST_KEY.ALL_ASSIGNED_TO_ME, wsUrlApiKey: API.WS_URL_KEY_MAPPER.USER }, CATEGORIES_OPTIONS_SHARED_DATA.ASSIGNED_TO_ME),
            Object.assign({ key: CATEGORY_LIST_KEY.UNASSIGNED, wsUrlApiKey: API.WS_URL_KEY_MAPPER.USER }, CATEGORIES_OPTIONS_SHARED_DATA.UNASSIGNED),
        ],
        agentList: [
            {
                key: CATEGORY_LIST_KEY.ALL_ASSIGNED_TO_ME,
                wsUrlApiKey: API.WS_URL_KEY_MAPPER.USER,
                title: 'Assigned to me',
                dataTestAttributes: {
                    list: 'cc-inquiries-list-assigned-to-me',
                    item: 'cc-inquiries-list-assigned-to-me-item',
                },
                toolbar: {
                    order: {
                        storageKey: INQUIRIES_ORDER_STORAGE_KEY.ALL_AGENT_ASSIGNED_LIST,
                    },
                },
                applyLoggedParams: true,
                query: {
                    options: {
                        fetchPolicy: 'cache-and-network',
                        nextFetchPolicy: 'cache-first',
                        variables: {
                            searchType: SEARCH_TYPE.DEFAULT,
                            isCompleted: false, // client boolean param for @skip gql directive
                            isHandedOff: false,
                            pagination: {
                                pageSize: MEDIUM_LIST_SIZE,
                            },
                        },
                        notifyOnNetworkStatusChange: true,
                    },
                },
            },
            {
                key: CATEGORY_LIST_KEY.UNASSIGNED,
                wsUrlApiKey: API.WS_URL_KEY_MAPPER.CALL_CENTER,
                title: 'Unassigned',
                dataTestAttributes: {
                    list: 'cc-inquiries-list-unassigned',
                    item: 'cc-inquiries-list-unassigned-item',
                },
                toolbar: {
                    order: {
                        storageKey: INQUIRIES_ORDER_STORAGE_KEY.ALL_AGENT_UNASSIGNED_LIST,
                    },
                },
                limitTo: SHORT_LIST_LIMIT_SIZE,
                refetchThreshold: SHORT_LIST_THERSHOLD_SIZE,
                skipPagination: true,
                clientFilterParams: {
                    assigned: {
                        id: null,
                    },
                },
                clientOrderParams: {
                    iteratees: ['msgStats.timerPeriod'],
                    orders: [
                        {
                            storageKey: INQUIRIES_ORDER_STORAGE_KEY.ALL_AGENT_UNASSIGNED_LIST,
                            shouldReverse: true,
                        },
                    ],
                },
                query: {
                    options: {
                        fetchPolicy: 'cache-and-network',
                        nextFetchPolicy: 'cache-first',
                        variables: {
                            searchType: SEARCH_TYPE.DEFAULT,
                            isCompleted: false, // client boolean param for @skip gql directive
                            isHandedOff: false,
                            filters: {
                                assignedTo: -1,
                            },
                            pagination: {
                                pageSize: SHORT_LIST_SIZE,
                            },
                        },
                        notifyOnNetworkStatusChange: true,
                    },
                },
            },
        ],
        userList: [
            Object.assign({ key: CATEGORY_LIST_KEY.ALL, wsUrlApiKey: API.WS_URL_KEY_MAPPER.USER }, CATEGORIES_OPTIONS_SHARED_DATA.ALL),
        ],
    },
    [CATEGORY.ASSIGNED_TO_OTHERS.key]: {
        list: [
            Object.assign({ key: CATEGORY_LIST_KEY.ASSIGNED, wsUrlApiKey: API.WS_URL_KEY_MAPPER.USER }, CATEGORIES_OPTIONS_SHARED_DATA.ASSIGNED_TO_OTHERS),
        ],
    },
    [CATEGORY.UNASSIGNED.key]: {
        clientFilterParams: {
            assigned: {
                id: null,
            },
        },
        list: [
            {
                key: CATEGORY_LIST_KEY.UNASSIGNED,
                wsUrlApiKey: API.WS_URL_KEY_MAPPER.USER,
                title: 'Unassigned',
                dataTestAttributes: {
                    list: 'cc-inquiries-list-unassigned',
                    item: 'cc-inquiries-list-unassigned-item',
                },
                toolbar: {
                    order: {
                        storageKey: INQUIRIES_ORDER_STORAGE_KEY.UNASSIGNED_LIST,
                    },
                },
                query: {
                    options: {
                        fetchPolicy: 'cache-and-network',
                        nextFetchPolicy: 'cache-first',
                        variables: {
                            searchType: SEARCH_TYPE.DEFAULT,
                            isCompleted: false, // client boolean param for @skip gql directive
                            isHandedOff: false,
                            filters: {
                                assignedTo: -1,
                                managed: false,
                            },
                            pagination: {
                                pageSize: MEDIUM_LIST_SIZE,
                            },
                        },
                        notifyOnNetworkStatusChange: true,
                    },
                },
            },
        ],
    },
    [CATEGORY.ASSIGNED_TO_ME.key]: {
        list: [
            {
                key: CATEGORY_LIST_KEY.ASSIGNED_TO_ME,
                wsUrlApiKey: API.WS_URL_KEY_MAPPER.USER,
                title: 'Assigned to me',
                dataTestAttributes: {
                    list: 'cc-inquiries-list-assigned-to-me',
                    item: 'cc-inquiries-list-assigned-to-me-item',
                },
                toolbar: {
                    order: {
                        storageKey: INQUIRIES_ORDER_STORAGE_KEY.ASSIGNED_TO_ME_LIST,
                    },
                },
                query: {
                    options: {
                        fetchPolicy: 'cache-and-network',
                        nextFetchPolicy: 'cache-first',
                        variables: {
                            searchType: SEARCH_TYPE.DEFAULT,
                            isCompleted: false, // client boolean param for @skip gql directive
                            isHandedOff: false,
                            pagination: {
                                pageSize: INFINITE_LIST_SIZE,
                            },
                        },
                        notifyOnNetworkStatusChange: true,
                    },
                },
            },
        ],
    },
    [CATEGORY.MANAGED.key]: {
        list: [
            Object.assign({ key: CATEGORY_LIST_KEY.MANAGED, wsUrlApiKey: API.WS_URL_KEY_MAPPER.USER }, CATEGORIES_OPTIONS_SHARED_DATA.MANAGED),
        ],
    },
    [CATEGORY.COMPLETED.key]: {
        dealerList: [
            {
                key: CATEGORY_LIST_KEY.COMPLETED,
                wsUrlApiKey: API.WS_URL_KEY_MAPPER.USER,
                title: 'Completed',
                dataTestAttributes: {
                    list: 'cc-inquiries-list-completed',
                    item: 'cc-inquiries-list-completed-item',
                },
                toolbar: {
                    order: {
                        storageKey: INQUIRIES_ORDER_STORAGE_KEY.COMPLETED_LIST,
                    },
                },
                query: {
                    options: {
                        fetchPolicy: 'cache-and-network',
                        nextFetchPolicy: 'cache-first',
                        variables: {
                            searchType: SEARCH_TYPE.COMPLETED,
                            isCompleted: true, // client boolean param for @skip gql directive
                            isHandedOff: false,
                            pagination: {
                                pageSize: LONG_LIST_SIZE,
                            },
                        },
                        notifyOnNetworkStatusChange: true,
                    },
                },
            },
        ],
        callCenterList: [
            Object.assign({ key: CATEGORY_LIST_KEY.COMPLETED, wsUrlApiKey: API.WS_URL_KEY_MAPPER.USER }, CATEGORIES_OPTIONS_SHARED_DATA.HANDED_OFF),
        ],
    },
    [CATEGORY.ARCHIVED.key]: {
        list: [
            {
                key: CATEGORY_LIST_KEY.ARCHIVED,
                wsUrlApiKey: API.WS_URL_KEY_MAPPER.USER,
                title: 'Archived',
                dataTestAttributes: {
                    list: 'cc-inquiries-list-archived',
                    item: 'cc-inquiries-list-archived-item',
                },
                toolbar: {
                    order: {
                        storageKey: INQUIRIES_ORDER_STORAGE_KEY.ARCHIVED_LIST,
                    },
                },
                query: {
                    options: {
                        fetchPolicy: 'cache-and-network',
                        nextFetchPolicy: 'cache-first',
                        variables: {
                            searchType: SEARCH_TYPE.ARCHIVED,
                            isCompleted: false,
                            isHandedOff: false,
                            pagination: {
                                pageSize: MEDIUM_LIST_SIZE,
                            },
                        },
                        notifyOnNetworkStatusChange: true,
                    },
                },
            },
        ],
    },
    [CATEGORY.UNANSWERED.key]: {
        list: [
            {
                key: CATEGORY_LIST_KEY.UNANSWERED,
                wsUrlApiKey: API.WS_URL_KEY_MAPPER.USER,
                title: 'Unanswered',
                dataTestAttributes: {
                    list: 'cc-inquiries-list-unanswered',
                    item: 'cc-inquiries-list-unanswered-item',
                },
                toolbar: {
                    order: {
                        storageKey: INQUIRIES_ORDER_STORAGE_KEY.UNANSWERED_LIST,
                    },
                },
                query: {
                    options: {
                        fetchPolicy: 'cache-and-network',
                        nextFetchPolicy: 'cache-first',
                        variables: {
                            searchType: SEARCH_TYPE.UNANSWERED,
                            isCompleted: false, // client boolean param for @skip gql directive
                            isHandedOff: false,
                            pagination: {
                                pageSize: MEDIUM_LIST_SIZE,
                            },
                        },
                        notifyOnNetworkStatusChange: true,
                    },
                },
            },
        ],
    },
    [CATEGORY.OUTBOUND.key]: {
        list: [
            {
                key: CATEGORY_LIST_KEY.OUTBOUND,
                wsUrlApiKey: API.WS_URL_KEY_MAPPER.USER,
                title: 'Outbound',
                dataTestAttributes: {
                    list: 'cc-inquiries-list-outbound',
                    item: 'cc-inquiries-list-outbound-item',
                },
                toolbar: {
                    order: {
                        storageKey: INQUIRIES_ORDER_STORAGE_KEY.OUTBOUND_LIST,
                    },
                },
                query: {
                    options: {
                        fetchPolicy: 'cache-and-network',
                        nextFetchPolicy: 'cache-first',
                        variables: {
                            searchType: SEARCH_TYPE.OUTBOUND,
                            isCompleted: false, // client boolean param for @skip gql directive
                            isHandedOff: false,
                            pagination: {
                                pageSize: MEDIUM_LIST_SIZE,
                            },
                        },
                        notifyOnNetworkStatusChange: true,
                    },
                },
            },
        ],
    },
};
